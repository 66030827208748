<template>
  <base-pagination
    v-model="page"
    :default="1"
    :per-page="rowsPerPage"
    :pageCount="pageCount"
    @update:modelValue="onPageChange"
    align="center"
    class="mt-3"
  />
</template>
<script>
export default {
  name: "CommonPagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.rowsPerPage);
    },
  },
  methods: {
    onPageChange(item) {
      this.$emit("page-change", item);
    },
  },
};
</script>
