<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">행사 {{ modalModeText }}</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="form-group mt-2">
          <base-input
            type="date-local"
            label="행사명"
            name="title"
            placeholder="행사명"
            v-model="newData.title"
          />
        </div>
        <div class="form-group mt-2">
          <label class="form-control-label">실천장소</label>
          <preset-select
            placeholder="실천장소"
            v-model="newData.place_id"
            :choices="[
              [221010, '정토사회문화회관'],
              [221020, '용문/여주 부지'],
              [221030, 'JTS안산다문화센터'],
              [221040, '두북정토수련원'],
              [221050, '정토미륵사'],
              [221060, '죽림정사'],
              [221070, '아도모례원'],
              [221080, '천룡사'],
              [221090, '부산 증생사'],
              [221100, '봉림사지'],
              [221110, '지리산 정토수련원'],
              [221120, '문경 정토수련원'],
              [221130, '봉화 정토수련원'],
            ]"
          />
        </div>
        <div class="form-group mt-2">
          <base-input
            type="datetime-local"
            label="행사일시"
            name="inv_dt"
            placeholder="행사일시"
            v-model="newData.inv_dt"
          />
        </div>
        <div class="form-group mt-2">
          <label class="form-control-label">주관단체</label>
          <preset-select
            placeholder="주관단체"
            v-model="newData.organization"
            :choices="[
              [223001, '개인'],
              [223002, '정토회'],
              [223003, '정토법당'],
              [223004, '평화재단'],
              [223005, '한국제이티에스'],
              [223006, '에코붓다'],
              [223007, '좋은벗들'],
            ]"
          />
        </div>
        <div class="form-group mt-2">
          <base-input
            type="date-local"
            label="주관부서"
            name="dept_cd"
            placeholder="주관부서"
            v-model="newData.dept_cd"
          />
        </div>
        <div class="form-group mt-2">
          <base-input
            type="text"
            label="발신번호"
            name="callback"
            placeholder="예시 010-0000-0000"
            v-model="newData.callback"
          />
        </div>
        <div class="form-group mt-2">
          <label class="form-control-label">안내문자메세지</label>
          <textarea
            class="form-control"
            v-model="newData.note"
            rows="5"
            placeholder=""
          ></textarea>
        </div>

        <div class="row">&nbsp;</div>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";

export default {
  components: {
    Modal,
    PresetSelect,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        place: "",
        title: "",
      },
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    // 등록하기
    onAdd() {
      const data = JSON.parse(JSON.stringify(this.newData));
      if (!data.title) {
        alert("행사명을 입력해주세요!!");
        return;
      } else if (!data.place_id) {
        alert("실천장소를 입력해주세요!!");
        return;
      } else if (!data.inv_dt) {
        alert("행사일시를 입력해주세요!!");
        return;
      } else if (!data.organization) {
        alert("주관단체를 입력해주세요!!");
        return;
      } else if (!data.dept_cd) {
        alert("주관부서를 입력해주세요!!");
        return;
      } else if (!data.callback) {
        alert("발신번호를 입력해주세요!!");
        return;
      }

      api.post("service/myinformation/entrywow", { data }).then((response) => {
        data.inv_id = response.data.msg;
        api.post("service/myinformation/entry", { data }).then(() => {
          this.$emit("addDone", data);
          this.hideModal();
        });
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));

      api
        .put(`service/myinformation/entrywow/${row.inv_id}`, { data })
        .then(() => {
          api
            .put(`service/myinformation/entry/${row.id}`, { data })
            .then(() => {
              this.$emit("editDone", row, data);
              this.hideModal();
            });
        });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.title = "";
      this.newData.place_id = "";
      this.newData.inv_dt = "";
      this.newData.organization = "";
      this.newData.dept_cd = "";
      this.newData.note = "";
      this.newData.writor = "";
      this.newData.callback = "";
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.newQuestion = "";
      this.loadModal(`service/myinformation/entry/${row.id}`, row, (data) => {
        return {
          title: data.title,
          place_id: data.place_id,
          inv_dt: data.inv_dt2,
          organization: data.organization,
          dept_cd: data.dept_cd,
          note: data.note,
          writor: data.writor,
          callback: data.callback,
        };
      });
    },
  },
};
</script>
