<!-- 공지게시판 관리 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">공지게시판 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-sm-2 mr-1">
                  <base-input
                    @change="getDataFromApi()"
                    placeholder="제목"
                    v-model="query.title"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2 mr-1">
                  <base-input
                    @change="getDataFromApi()"
                    placeholder="수신처"
                    v-model="query.receivedTeam"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2 mr-1 mb-4">
                  <input
                    @change="getDataFromApi()"
                    type="date"
                    class="form-control col"
                    placeholder="날짜(YYYY-MM-DD)"
                    v-model="query.startDate"
                  />
                </div>

                <div class="col-sm-1 mb-4">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div class="col-sm-1 mb-4">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="reset()"
                    >초기화</base-button
                  >
                </div>
                <div class="col text-right">
                  <!-- New Button -->
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
              <div class="col text-right">
                <base-button
                  class="btn-sm"
                  type="secondary"
                  @click.prevent="goCal()"
                  >월별보기</base-button
                >
              </div>
            </div>
            <!-- End Search, New Button -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->
            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'no', order: 'ascescending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="번호" sortable min-width="80px" prop="no">
              </el-table-column>
              <el-table-column
                label="게시날짜"
                sortable
                min-width="100px"
                prop="createdTime"
              >
              </el-table-column>
              <el-table-column
                label="시작날짜"
                sortable
                min-width="100"
                prop="startDate"
              >
              </el-table-column>
              <el-table-column
                label="종료날짜"
                sortable
                min-width="100px"
                prop="endDate"
              >
              </el-table-column>
              <el-table-column
                label="공지기간"
                sortable
                min-width="70px"
                prop="all"
              >
              </el-table-column>
              <el-table-column
                label="발신처"
                sortable
                min-width="70px"
                prop="sendTeam"
              >
              </el-table-column>
              <el-table-column
                label="수신처"
                sortable
                min-width="100px"
                prop="receivedTeam"
              >
              </el-table-column>
              <el-table-column
                label="수신지부"
                sortable
                min-width="100px"
                prop="receivedLocal"
              >
              </el-table-column>
              <el-table-column
                label="공지제목"
                sortable
                min-width="200px"
                prop="title"
              >
                <template v-slot="{ row }">
                  <span class="clickable" @click="goContent(row)">
                    <div class="font-weight-600">
                      <p v-html="row.title"></p>
                    </div>
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="조회"
                sortable
                min-width="100px"
                prop="hit"
              >
              </el-table-column>
              <el-table-column
                label="댓글/대댓글"
                sortable
                min-width="120px"
                prop="commentHit2"
              >
              </el-table-column>
              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                    @click.stop="handleEdit(row)"
                  >
                    <i class="text-white fa fa-pen" />
                  </base-button>
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    @click.stop="handleDelete(row)"
                  >
                    <i class="text-white ni ni-fat-remove" />
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModal.vue";
import moment from "moment";
import * as api from "@/api";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
  },
  computed: {
    content() {
      return this.content.split("\n").join("<br>");
    },
  },

  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      query: {
        // 검색 쿼리
        title: "",
        receivedTeam: "",
        startDate: "",
      },
      newData: {
        // 새 등록 데이터
      },
      inputPossible: false,
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    goCal() {
      this.$router.push({
        name: "공지게시판관리(월별보기)",
      });
    },
    reset() {
      this.query.title = "";
      this.query.receivedTeam = "";
      this.query.startDate = "";
      this.getDataFromApi();
    },
    getContent(content) {
      return content.split("\n").join("<br>");
    },
    // api 호출
    getDataFromApi(page = 1) {
      this.loading = true;
      //쿼리조건 추가

      const url = `service/myinformation/noticeManage2?bound=${this.perPage}&page=${page}&title=${this.query.title}&receivedTeam=${this.query.receivedTeam}&startDate=${this.query.startDate}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
            if (row.all === "기간") {
              row.startDate = moment(row.startDate).format("YYYY-MM-DD");
              row.endDate = moment(row.endDate).format("YYYY-MM-DD");
            }
            if (row.read) {
              row.hit = row.contentHit + "/" + row.read.length;
            } else {
              row.hit = row.contentHit + "/0";
            }

            if (row.receivedTeam && row.receivedTeam.length > 0) {
              let item2 = "";
              let i = 0;
              for (const item of row.receivedTeam) {
                if (i === 0) {
                  item2 = item;
                } else {
                  item2 = item2 + ", " + item;
                }
                i++;
              }
              row.receivedTeam = item2;
            }
            if (row.receivedLocal && row.receivedLocal.length > 0) {
              let item2 = "";
              let i = 0;
              for (const item of row.receivedLocal) {
                if (i === 0) {
                  item2 = item;
                } else {
                  item2 = item2 + ", " + item;
                }
                i++;
              }
              row.receivedLocal = item2;
            }
            let commentHit4 = row.commentHit2.reduce(function add(
              sum,
              currValue
            ) {
              return sum + currValue;
            },
            0);
            let commentHit5 = 0;
            if (row.double) {
              let i = 0;
              for (const item of row.double) {
                i = i + item.length;
              }
              commentHit5 = i;
            }
            row.commentHit2 = commentHit4 + "/" + commentHit5;
          }
        }

        this.tableData = d;
        this.total = data.total;
      });
    },
    goContent(row) {
      const token = api.Auth.tokenContent;
      let data = {};
      if (!row.read.includes(token.memberNo)) {
        row.read.push(token.memberNo);
        data.read = row.read;
      }
      if (row.contentHit) {
        data.contentHit = row.contentHit + 1;
      } else {
        data.contentHit = 1;
      }
      api
        .put(`service/myinformation/noticeManage/${row.id}`, { data })
        .then(() => {});
      this.$router.push({
        name: "댓글쓰기",
        query: {
          suid: row.id,
        },
      });
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      const data = {};
      data.state = 0;
      if (confirm("삭제하시겠습니까?")) {
        api
          .put(`service/myinformation/noticeManage/${row.id}`, { data })
          .then(() => {
            this.tableData = this.tableData.filter((r) => r.id !== row.id);
          });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
