<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">답변</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row">
          <label class="form-control-label ml-3">요청업무</label>
        </div>
        <div class="row ml-0">
          <p>{{ newData.questPart }}</p>
        </div>
        <div class="form-group mt-2">
          <label class="form-control-label">문의내용</label>
          <textarea
            disabled
            class="form-control"
            v-model="newData.question"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group mt-2" v-if="newData.resultContent">
          <label class="form-control-label">답변내용</label>
          <textarea
            disabled
            class="form-control"
            v-model="newData.resultContent"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group mt-2">
          <label class="form-control-label" v-if="newData.resultContent"
            >추가답변</label
          >
          <label class="form-control-label" v-else>답변</label>
          <textarea
            class="form-control"
            v-model="newResultContent"
            rows="5"
          ></textarea>
        </div>
        <preset-radio
          v-model="newData.result"
          :choices="[
            ['질문보완요청', '질문보완요청'],
            ['접수확인', '접수확인'],
            ['처리완료', '처리완료'],
          ]"
        />
        <div class="row">&nbsp;</div>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >등 록</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";
import PresetRadio from "@/components/Inputs/PresetRadio";

export default {
  components: {
    Modal,
    PresetRadio,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        questPart: "",
        question: "",
        resultContent: "",
      },
      newResultContent: "",
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      if (data.resultContent) {
        data.resultContent =
          this.newResultContent + "\n\n" + data.resultContent;
      } else {
        data.resultContent = this.newResultContent;
      }
      data.type = 2;
      api.put(`service/myinformation/question/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.question = "";
      this.newData.questPart = "";
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.newResultContent = "";
      this.loadModal(
        `service/myinformation/question/${row.id}`,
        row,
        (data) => {
          return {
            question: data.question,
            questPart: data.questPart,
            resultContent: data.resultContent,
            result: data.result,
          };
        }
      );
    },
  },
};
</script>
