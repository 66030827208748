<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">기도 {{ modalModeText }}</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row">
          <label class="form-control-label ml-3">기도 선택</label>
        </div>
        <div class="row" v-if="modalModeText == '등록'">
          <preset-select
            class="col-sm-8"
            v-model="newData.eduName"
            :choices="eduNames"
          />
        </div>
        <div class="row ml-0" v-else>
          <preset-select
            disabled
            v-model="newData.eduName"
            :choices="eduNames"
          />
        </div>
        <div class="row">&nbsp;</div>
        <div class="form-group mt-2">
          <label class="form-control-label"
            >나누기: 기도를 마친 지금 마음을 나눠주세요</label
          >
          <textarea
            class="form-control"
            v-model="newData.content"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group mt-2">
          <base-input
            disabled
            type="date-local"
            label="날짜"
            name="date"
            placeholder="date"
            v-model="newData.insertDate"
          />
        </div>

        <div class="row">&nbsp;</div>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";
import moment from "moment";
import { Auth } from "@/api";
import util from "@/util/util";

export default {
  components: {
    Modal,
    PresetSelect,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        eduName: "",
        content: "",
        insertDate: "",
      },
      eduNames: [],
      isShare: false,
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      let eduOrder = "";
      if (sessionStorage.getItem("shareRole") === "student") {
        eduOrder = sessionStorage.getItem("eduOrder");
      }
      const url2 = `service/myinformation/edu2?eduOrder=${eduOrder}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        let edu = [];
        if (data.rows > 0) {
          for (const row of data.data) {
            edu.push(row.name);
            edu.push(row.name);
            this.eduNames.push(edu);
            edu = [];
          }
        }
      });
    },
    // 등록하기
    onAdd() {
      const token = Auth.tokenContent;
      const data = JSON.parse(JSON.stringify(this.newData));
      //const regex = /[^0-9]/g;
      let temp = data.eduName.split("차");
      data.eduOrder = temp[0];
      if (this.newData.eduName === "") {
        alert("기도를 선택해주세요");
        return;
      }
      if (this.newData.content.length < 2) {
        alert("기도 내용이 없습니다.");
        return;
      }
      let url3 = "";
      if (sessionStorage.getItem("shareRole") === "student") {
        this.isShare = true;
        url3 = `service/myinformation/eduStudent?memberNo=${token.memberNo}`;
      } else if (sessionStorage.getItem("shareRole") === "groupChief") {
        this.isShare = true;
        url3 = `service/myinformation/eduStudent?groupChief=${token.memberNo}&eduOrder=${data.eduOrder}`;
      } else {
        this.isShare = false;
        alert("기도 나누기 등록 권한이 없습니다.");
      }
      if (this.isShare) {
        api.get(url3).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          for (let i = 0; i < data2.rows; i++) {
            if (data2.data[i].eduName.includes(data.eduName)) {
              data.eduId = data2.data[i].eduId;
            }
          }
          data.groupName = data2.data[0].groupName;
          data.groupChief = data2.data[0].groupChief;
          data.mentor = data2.data[0].mentor;
          data.type = sessionStorage.getItem("shareRole");
          let isInsert = true;
          if (data.eduName.includes("일요명상")) {
            const today = new Date();
            const day = today.getDay();
            if (day === 0) {
              isInsert = true;
            } else {
              isInsert = false;
              alert("일요일에만 입력이 가능합니다.");
            }
          }
          const url4 = `service/myinformation/share?memberNo=${token.memberNo}&eduOrder=${data.eduOrder}&insertDate=${data.insertDate}&eduName=${data.eduName}&groupName=${data.groupName}`;
          api.get(url4).then((response2) => {
            if (!api.isSuccess(response2)) {
              return;
            }
            if (response2.data.rows > 0) {
              alert(
                "오늘(" +
                  data.insertDate +
                  ") 나누기는 입력하셨습니다.수정은 수정버튼을 클릭해서 하시면 됩니다."
              );
            } else {
              if (isInsert) {
                api.post("service/myinformation/share", { data }).then(() => {
                  this.$emit("addDone", data);
                  this.hideModal();
                });
              }
            }
          });
        });
      }
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.put(`service/myinformation/share/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.eduName = "";
      this.newData.content = "";
      this.newData.insertDate = moment().format("YYYY-MM-DD");
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.newQuestion = "";
      this.loadModal(`service/myinformation/share/${row.id}`, row, (data) => {
        return {
          eduName: data.eduName,
          content: util.getDecrypt(data.content),
          insertDate: data.insertDate,
        };
      });
    },
  },
};
</script>
