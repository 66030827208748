<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}2</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <a
            href="#"
            @click.prevent="prev"
            class="fullcalendar-btn-prev btn btn-sm btn-default"
          >
            <i class="fas fa-angle-left"></i>
          </a>
          <a
            href="#"
            @click.prevent="next"
            class="fullcalendar-btn-next btn btn-sm btn-default"
          >
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridMonth')"
          >
            Month
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('timeGridWeek')"
          >
            Week
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('timeGridDay')"
          >
            Day2
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <div class="row">
                <h5 class="h3 mb-0">
                  {{ year }}년 {{ month + 1 }}월
                  <span v-if="isDay">{{ day }}일</span>
                </h5>
                <div class="col mr-2 text-right">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="goTable2()"
                    >목록보기</base-button
                  >
                </div>
              </div>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <div id="fullCalendar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model:show="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input
          name="title"
          label="Event title"
          placeholder="Event Title"
          v-model="model.title"
          input-classes="form-control-alternative new-event--title"
        >
        </base-input>
        <div class="form-group">
          <label class="form-control-label d-block mb-3">Status color</label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">
            <label
              v-for="color in eventColors"
              :key="color"
              class="btn"
              :class="[color, { 'active focused': model.className === color }]"
            >
              <input
                v-model="model.className"
                type="radio"
                name="event-tag"
                :value="color"
                autocomplete="off"
              />
            </label>
          </div>
        </div>
        <input type="hidden" class="new-event--start" />
        <input type="hidden" class="new-event--end" />
      </form>

      <template v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="saveEvent"
        >
          Add event
        </button>
        <button type="button" class="btn btn-link ml-auto" @click="close">
          Close
        </button>
      </template>
    </modal>

    <modal v-model:show="showEditModal" modal-classes="modal-secondary">
      <form class="edit-event--form">
        <base-input name="textarea" label="제목">
          <div
            v-html="model.title"
            class="
              form-control form-control-alternative
              edit-event--description
              textarea-autosize
            "
          ></div>
          <i class="form-group--bar"></i>
        </base-input>
        <base-input name="textarea" label="내용">
          <div v-html="model.description"></div>
          <i class="form-group--bar"></i>
        </base-input>
      </form>

      <template v-slot:footer>
        <base-button type="link" class="ml-auto" @click="close"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import moment from "moment";
import * as api from "@/api";

const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();
const d = today.getDate();
var calendar;
export default {
  name: "calendar",
  components: {
    RouteBreadCrumb,
    Modal,
  },
  data() {
    return {
      year: y,
      month: m,
      day: d,
      isDay: false,
      events: [],
      model: {
        title: "New event",
        className: "bg-default",
        description:
          "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        start: "",
        end: "",
      },
      showAddModal: false,
      showEditModal: false,
      eventColors: [
        "bg-info",
        "bg-orange",
        "bg-green",
        "bg-default",
        "bg-blue",
        "bg-purple",
        "bg-yellow",
        "bg-red",
      ],
    };
  },

  methods: {
    goTable2() {
      this.$router.push({
        name: "공지게시판관리",
      });
    },
    initCalendar() {
      const url = `service/myinformation/noticeService?bound=100`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        let i = 0;
        if (data.rows > 0) {
          for (const row of d) {
            if (row.startDate) {
              const data = {};
              let startYear = moment(row.startDate).format("YYYY");
              let startMonth = moment(row.startDate).format("MM") - 1;
              let startDay = moment(row.startDate).format("DD");
              let startHour = moment(row.startDate).format("hh");
              let startMinute = moment(row.startDate).format("mm");
              data.start = new Date(
                startYear,
                startMonth,
                startDay,
                startHour,
                startMinute
              );
              let endYear = moment(row.endDate).format("YYYY");
              let endMonth = moment(row.endDate).format("MM") - 1;
              let endDay = moment(row.endDate).format("DD");
              let endHour = moment(row.endDate).format("hh");
              let endMinute = moment(row.endDate).format("mm");
              data.end = new Date(
                endYear,
                endMonth,
                endDay,
                endHour,
                endMinute
              );
              data.title = row.title;
              data.description = row.content.replace(
                /class="ql-align-center"/gi,
                'align="center"'
              );
              data.allDay = true;
              data.className = this.eventColors[i];
              this.events.push(data);
              i++;
            }
          }
        }
        var calendarEl = document.getElementById("fullCalendar");

        calendar = new Calendar(calendarEl, {
          plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
          selectable: true,
          headerToolbar: false,
          contentHeight: "auto",
          select: (info) => {
            this.showAddModal = true;
            this.model.start = info.startStr;
            this.model.end = info.endStr;
          },
          eventClick: (event) => {
            this.model = {
              title: event.event.title,
              className: event.event.classNames
                ? event.event.classNames.join(" ")
                : "",
              start: event.event.start,
              end: event.event.end,
              description: event.event.extendedProps.description,
            };
            this.showEditModal = true;
          },
          events: this.events,
        });
        calendar.render();
      });
    },
    changeView(newView) {
      if (newView === "timeGridDay") {
        this.isDay = true;
      } else {
        this.isDay = false;
      }
      calendar.changeView(newView);
      calendar.view.title;
    },
    next() {
      calendar.next();
      this.year = calendar.getDate().getFullYear();
      this.month = calendar.getDate().getMonth();
      this.day = calendar.getDate().getDate();
    },
    prev() {
      calendar.prev();
      this.year = calendar.getDate().getFullYear();
      this.month = calendar.getDate().getMonth();
      this.day = calendar.getDate().getDate();
    },
    saveEvent() {
      if (this.model.title) {
        let event = {
          ...this.model,
        };
        this.events.push(JSON.parse(JSON.stringify(event)));
        this.model = {
          title: "",
          eventColor: "bg-danger",
          start: "",
          end: "",
        };
      }
      this.showAddModal = false;
    },
    editEvent() {
      let index = this.events.findIndex((e) => e.title === this.model.title);
      if (index !== -1) {
        this.events.splice(index, 1, this.model);
      }
      this.showEditModal = false;
    },
    deleteEvent() {
      let index = this.events.findIndex((e) => e.title === this.model.title);
      if (index !== -1) {
        this.events.splice(index, 1);
      }
      this.showEditModal = false;
    },
    close() {
      this.showAddModal = false;
      this.showEditModal = false;
    },
  },
  mounted() {
    this.initCalendar();
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/core/vendors/fullcalendar";
</style>
