<template>
  <ul class="list-group list-group-flush list my--3">
    <li class="list-group-item px-0" v-for="user in users" :key="user.id">
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- Avatar -->
          <a href="#" class="avatar rounded-circle">
            <img alt="Image placeholder" :src="user.img" />
          </a>
        </div>
        <div class="col ml--2">
          <h4 class="mb-0">
            <a href="#"> {{ user.name }}</a>
          </h4>
          <span :class="`text-${user.statusType}`">● </span>
          <small>{{ user.status }}</small>
        </div>
        <div class="col-auto">
          <a href="#"
            ><base-button type="primary" size="sm">바로가기</base-button></a
          >
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "user-list",
  data() {
    return {
      users: [
        {
          id: 1,
          img: "img/theme/team-1.jpg",
          name: "예제 게시판",
          status: "possible",
          statusType: "success",
          link: "example",
        },
        {
          id: 2,
          img: "img/theme/team-2.jpg",
          name: "법회",
          status: "possible",
          statusType: "success",
          link: "dharma",
        },
        {
          id: 3,
          img: "img/theme/team-3.jpg",
          name: "관리",
          status: "possible",
          statusType: "success",
          link: "manage/dharma",
        },
        {
          id: 4,
          img: "img/theme/team-4.jpg",
          name: "공지",
          status: "impossible",
          statusType: "danger",
          link: "",
        },
      ],
    };
  },
};
</script>
<style></style>
