<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">대댓글 {{ modalModeText }}</h6>
    </template>
    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row">
          <label class="form-control-label">대댓글</label>
        </div>
        <div :key="htmlEditorKey">
          <html-editor v-model="content" />
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin";
import * as api from "@/api";
import HtmlEditor from "@/components/Inputs/HtmlEditor.vue";
import moment from "moment-timezone";

export default {
  components: {
    Modal,
    HtmlEditor,
  },
  props: ["commentId", "tableData2"],
  mixins: [EditModalMixin],
  data() {
    return {
      content: "",
      htmlEditorKey: 0,
      tableData3: [],
      no: 0,
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    // 등록하기
    onAdd() {
      const token = api.Auth.tokenContent;
      const data = {};
      const aa = {};
      aa.name = "관리자";
      aa.dept = "지원국";
      aa.memberNo = token.memberNo;
      aa.content = this.content;
      aa.createdTime = moment().format("YYYY-MM-DD hh-mm");
      aa.no = this.tableData2.length + 1;
      this.tableData3 = this.tableData2;
      this.tableData3.unshift(aa);
      data.doubleComment = this.tableData2;
      data.type = "doubleComment";
      api
        .put(`service/myinformation/comment/${this.commentId}`, { data })
        .then(() => {
          this.$emit("editDone", this.commentId, data);
          this.hideModal();
        });
    },
    // 수정하기
    onEdit() {
      const token = api.Auth.tokenContent;
      const data = {};
      const aa = {};
      aa.name = "관리자";
      aa.dept = "지원국";
      aa.memberNo = token.memberNo;
      aa.content = this.content;
      aa.no = this.no + 1;
      aa.createdTime = moment().format("YYYY-MM-DD hh-mm");

      this.tableData3[this.no] = aa;
      data.doubleComment = this.tableData3;
      data.type = "doubleComment";
      api
        .put(`service/myinformation/comment/${this.commentId}`, { data })
        .then(() => {
          this.$emit("editDone", this.commentId, data);
          this.hideModal();
        });
    },
    // 등록창 열기
    handleAdd() {
      this.content = "";
      this.htmlEditorKey++;
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row, row2, index) {
      this.htmlEditorKey++;
      this.no = index;
      this.content = row.content;
      this.tableData3 = row2;
      this.showEditModal();
    },
  },
};
</script>
