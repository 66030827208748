<template>
  <quill-editor
    theme="snow"
    contentType="html"
    :content="modelValue"
    :toolbar="toolbar"
    @update:content="this.$emit('update:modelValue', $event)"
  />
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "html-editor",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    toolbar: {
      type: String,
      default: "full",
    },
  },
  components: {
    QuillEditor,
  },
};
</script>
