<!-- 로그인 페이지 -->
<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-4">
          <div class="row justify-content-center">
            <div>
              <h1 class="text-white">{{ TITLE }}</h1>
              <!-- <h1 class="text-white">테스트 사이트</h1> -->
              <p></p>
              <p class="text-lead text-white">환영합니다!!</p>
              <p class="text-lead text-white">
                정토 플랫폼을 이용하시려면 아래 버튼을 눌러 로그인해주세요!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card border-0 mb-0">
            <div class="card-header bg-transparent pb-4">
              <div class="btn-wrapper text-center">
                <a
                  href="#"
                  class="btn btn-neutral btn-icon"
                  @click.prevent="login"
                >
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/google.svg"
                  /></span>
                  <span class="btn-inner--text">Google 로그인</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TITLE } from "@/consts/names";
import { googleOneTap, googleTokenLogin } from "vue3-google-login";
import axios from "axios";

export default {
  data() {
    return {
      TITLE,
    };
  },
  methods: {
    login() {
      googleOneTap()
        .then((res) => {
          // This promise is resolved when user selects an account from the the One Tap prompt
          const axiosOptions = {
            headers: { "Content-Type": "application/json" },
          };
          const API_URL = process.env.VUE_APP_API_BASE_URL;
          axios
            .post(`${API_URL}/base/auth2`, res, axiosOptions)
            .then((res) => {
              const payload = res.data.data;

              if (payload) {
                sessionStorage.setItem("accessToken", payload.accessToken);
                sessionStorage.setItem("refreshToken", payload.refreshToken);
                sessionStorage.setItem("email", payload.email);
                sessionStorage.setItem("name", payload.name);
                sessionStorage.setItem("nickname", payload.nickname);
                sessionStorage.setItem("role", payload.role);

                if (payload?.picture)
                  sessionStorage.setItem("picture", payload.picture);

                if (sessionStorage.getItem("fullPath"))
                  document.location.replace(
                    "/#" + sessionStorage.getItem("fullPath")
                  );
                else document.location.replace("/");

                sessionStorage.removeItem("fullPath");
              } else {
                alert("login failure");
                document.location.replace("/#/login");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("Handle the error", error);
          googleTokenLogin().then((response) => {
            console.log("Handle the response", response);
            const axiosOptions = {
              headers: { "Content-Type": "application/json" },
            };
            const API_URL = process.env.VUE_APP_API_BASE_URL;
            axios
              .post(`${API_URL}/base/auth2`, response, axiosOptions)
              .then((res) => {
                const payload = res.data.data;

                if (payload) {
                  sessionStorage.setItem("accessToken", payload.accessToken);
                  sessionStorage.setItem("refreshToken", payload.refreshToken);
                  sessionStorage.setItem("email", payload.email);
                  sessionStorage.setItem("name", payload.name);
                  sessionStorage.setItem("nickname", payload.nickname);
                  sessionStorage.setItem("role", payload.role);

                  if (payload?.picture)
                    sessionStorage.setItem("picture", payload.picture);

                  if (sessionStorage.getItem("fullPath"))
                    document.location.replace(
                      "/#" + sessionStorage.getItem("fullPath")
                    );
                  else document.location.replace("/");

                  sessionStorage.removeItem("fullPath");
                } else {
                  alert("login failure");
                  document.location.replace("/#/login");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
    },
  },
};
</script>
