<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          {{ TITLE }}
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a href="https://jungto.org" class="nav-link" target="_blank"
              >정토회</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.jungto.org/privacy"
              class="nav-link"
              target="_blank"
              >개인정보 취급방침</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
import { TITLE } from "@/consts/names";
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      TITLE,
    };
  },
};
</script>
<style></style>
