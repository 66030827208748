<!-- 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">공지게시판</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="col text-right mt-2">
              <!-- New Button -->
              <base-button type="primary" class="btn-sm" @click="goList"
                >공지목록</base-button
              >
              <!-- End New Button -->
            </div>
            <div class="card-body text-center">
              <table align="center" width="80%">
                <td class="list-group-item px-2">
                  <h4 class="text-left">공지제목</h4>
                  <p class="text-left" v-html="title"></p>
                  <hr />
                  <h4 class="text-left">공지내용</h4>
                  <p class="text-left" v-html="content"></p>
                  <div class="text-center mt-4"></div>
                </td>
                <br />
              </table>
            </div>
            <div class="col text-right mb-2">
              <!-- New Button -->
              <base-button type="primary" class="btn-sm" @click="goList"
                >공지목록</base-button
              >
              <!-- End New Button -->
            </div>
          </div>
          <div class="card" v-if="isComment">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">댓글</h3>
                </div>
              </div>
            </div>

            <div class="card-body text-center">
              <!-- Search -->
              <form class="form-inline mb-2">
                <div class="col-sm-2">
                  <preset-select
                    @change="getDataFromApi(1)"
                    placeholder="지부"
                    v-model="query.local"
                    :choices="LOCAL.map((x) => [x, `${x}`])"
                  />
                </div>
                <div class="col-sm-2">
                  <preset-select
                    @change="getDataFromApi(1)"
                    placeholder="지회"
                    v-model="query.district"
                    :choices="districts.map((x) => [x, `${x}`])"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    type="text"
                    class="form-control col"
                    placeholder="모둠"
                    v-model="query.modum"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    type="text"
                    class="form-control col"
                    placeholder="회원이름"
                    v-model="query.name"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    type="text"
                    class="form-control col"
                    placeholder="댓글내용"
                    v-model="query.comment"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    type="text"
                    class="form-control col"
                    placeholder="대댓글내용"
                    v-model="query.doubleComment"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>

                <div class="col-sm-1 mt-2">
                  <base-button
                    class="btn-md"
                    type="secondary"
                    @click.prevent="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div class="col-sm-2 mt-2">
                  <base-button
                    class="btn-md"
                    type="secondary"
                    @click.prevent="reset()"
                    >초기화</base-button
                  >
                </div>
              </form>
              <div class="col text-right">
                <!-- New Button -->
                <base-button type="primary" class="btn-lg" @click="handleAdd"
                  >+댓글추가</base-button
                >
                <!-- End New Button -->
              </div>
              <!-- modal -->
              <edit-modal
                ref="editModal"
                :noticeId="noticeId"
                @addDone="onAddDone"
                @editDone="onEditDone"
              />
              <edit-modal2
                ref="editModal2"
                :commentId="commentId"
                :tableData2="tableData2"
                @addDone="onAddDone2"
                @editDone="onEditDone2"
              />
              <!-- End Search -->
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead v-if="pc">
                    <th width="5%" min-width="50px">번호</th>
                    <th width="20%" min-width="150px">소속</th>
                    <th width="10%" min-width="100px">이름</th>
                    <th width="40%" min-width="250px">댓글</th>
                    <th width="10%" min-width="150px">작성일</th>
                    <th width="15%" min-width="150px">Action</th>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div class="card-body mx-0 px-0">
                <table align="center" width="100%">
                  <ul class="list-group list-group-flush list my--3">
                    <li
                      v-for="row in tableData"
                      :key="row.id"
                      class="list-group px-0"
                    >
                      <tr class="list-group-item" v-if="pc">
                        <td width="5%" min-width="30px">{{ row.no }}</td>
                        <td width="20%" min-width="150px">{{ row.dept }}</td>
                        <td width="10%" min-width="100px">{{ row.name }}</td>
                        <td width="40%" min-width="250px">
                          <div
                            v-html="row.comment"
                            style="word-break: break-all; text-align: left"
                          ></div>
                        </td>
                        <td width="10%" min-width="150px">
                          {{ row.createdTime }}
                        </td>
                        <td width="15%" min-width="150px">
                          <div class="d-flex">
                            <base-button
                              class="edit"
                              type="primary"
                              size="sm"
                              @click.stop="doubleComment(row)"
                            >
                              대댓글
                            </base-button>
                            <base-button
                              v-if="row.isEdit"
                              size="sm"
                              type="primary"
                              @click.stop="handleEdit(row)"
                            >
                              수정
                            </base-button>
                            <base-button
                              v-if="row.isEdit"
                              size="sm"
                              type="warning"
                              @click.stop="handleDelete(row)"
                            >
                              삭제
                            </base-button>
                          </div>
                        </td>
                      </tr>
                      <tr class="list-group-item" v-else>
                        <p>번호: {{ row.no }}</p>
                        <p>소속: {{ row.dept }}</p>
                        <p>이름: {{ row.name }}</p>

                        <div
                          v-html="row.comment"
                          style="word-break: break-all; text-align: left"
                        ></div>

                        <p>작성일: {{ row.createdTime }}</p>

                        <div class="d-flex mb-1">
                          <base-button
                            class="edit"
                            type="primary"
                            size="sm"
                            @click.stop="doubleComment(row)"
                          >
                            대댓글
                          </base-button>
                          <base-button
                            v-if="row.isEdit"
                            size="sm"
                            type="primary"
                            @click.stop="handleEdit(row)"
                          >
                            수정
                          </base-button>
                          <base-button
                            v-if="row.isEdit"
                            size="sm"
                            type="warning"
                            @click.stop="handleDelete(row)"
                          >
                            삭제
                          </base-button>
                        </div>
                      </tr>

                      <ul class="list-group list-group-flush list my--2">
                        <li
                          v-for="(row2, index) in row.doubleComment"
                          :key="index"
                          class="list-group px-0"
                        >
                          <tr class="list-group-item" v-if="pc">
                            <td width="1%"></td>
                            <td width="5%" min-width="50px">{{ row2.no2 }}</td>
                            <td width="20%" min-width="150px">
                              {{ row2.dept }}
                            </td>
                            <td width="10%" min-width="100px">
                              {{ row2.name }}
                            </td>
                            <td width="40%" min-width="250px">
                              <div
                                v-html="row2.content"
                                style="word-break: break-all; text-align: left"
                              ></div>
                            </td>
                            <td width="10%" min-width="150px">
                              {{ row2.createdTime }}
                            </td>
                            <td width="15%" min-width="150px">
                              <div class="d-flex" v-if="row2.isEdit">
                                <base-button
                                  size="sm"
                                  type="primary"
                                  @click.stop="handleEdit2(row2, row, index)"
                                >
                                  수정
                                </base-button>
                                <base-button
                                  size="sm"
                                  type="warning"
                                  @click.stop="handleDelete2(row, index)"
                                >
                                  삭제
                                </base-button>
                              </div>
                            </td>
                          </tr>
                          <tr class="list-group-item" v-else>
                            <p>번호: {{ row2.no2 }}</p>
                            <p>소속: {{ row2.dept }}</p>
                            <p>
                              이름:
                              {{ row2.name }}
                            </p>
                            내용:
                            <div
                              v-html="row2.content"
                              style="word-break: break-all; text-align: left"
                            ></div>

                            <p>
                              작성일:
                              {{ row2.createdTime }}
                            </p>

                            <div class="d-flex" v-if="row2.isEdit">
                              <base-button
                                size="sm"
                                type="primary"
                                @click.stop="handleEdit2(row2, row, index)"
                              >
                                수정
                              </base-button>
                              <base-button
                                size="sm"
                                type="warning"
                                @click.stop="handleDelete2(row, index)"
                              >
                                삭제
                              </base-button>
                            </div>
                          </tr>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </table>
              </div>

              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import EditModal from "./Board/EditModal2.vue";
import EditModal2 from "./Board/EditModal3.vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import { LOCAL, getLocalNames } from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    PresetSelect,
    EditModal,
    EditModal2,
  },
  // 데이터 객체 추가
  data() {
    return {
      LOCAL,
      getLocalNames,
      districts: [],
      tableData: [], // 레코드셋
      tableData2: [], // 레코드셋
      perPage: 10, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      start1: 0,
      start2: 0,
      end1: 0,
      end2: 0,
      content: "",
      id: "",
      title: "",
      verboseSearchOptionManager: false,
      inputPossibleBranch: false,
      inputPossibleClassLeader: false,
      noticeId: "",
      commentId: "",
      isComment: false,
      pc: false,
      header: [],
      query: {
        // 검색 쿼리
        local: "",
        district: "",
        modum: "",
        name: "",
        comment: "",
        doubleComment: "",
      },
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    reset() {
      this.query.local = "";
      this.query.districts = "";
      this.query.modum = "";
      this.query.name = "";
      this.query.comment = "";
      this.query.doubleComment = "";
      this.getDataFromApi(1);
    },
    getContent(content) {
      return content.split("\n").join("<br>");
    },
    // api 호출
    getDataFromApi(page = 1) {
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (b < a) {
        this.pc = true;
      }
      this.loading = true;
      this.header = ["번호", "소속", "이름", "댓글", "작성일", "Action"];
      if (this.query.local) {
        this.districts = getLocalNames(this.query.local);
      }
      this.noticeId = this.$route.query.suid;
      const url = `service/myinformation/noticeManage/${this.$route.query.suid}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const d = response.data.data;
        this.isComment = d.isComment;
        this.start1 = moment(d.startDate).format("M");
        this.start2 = moment(d.startDate).format("D");
        this.end1 = moment(d.endDate).format("M");
        this.end2 = moment(d.endDate).format("D");
        if (d.content.includes("\n")) {
          this.content = this.getContent(d.content);
          this.content = this.content.replace(
            /class="ql-align-center"/gi,
            'align="center"'
          );
        } else {
          this.content = d.content.replace(
            /class="ql-align-center"/gi,
            'align="center"'
          );
        }
        this.title = d.title;
        this.commentHit = d.commentHit;
        this.id = d.id;

        const url2 = `service/myinformation/comment?page=${page}&doubleComment=${this.query.doubleComment}&local=${this.query.local}&district=${this.query.district}&modum=${this.query.modum}&noticeId=${this.$route.query.suid}&name=${this.query.name}&comment=${this.query.comment}`;
        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d2 = data2.data;
          const token = api.Auth.tokenContent;

          if (data2.rows > 0) {
            moment.locale("ko");
            for (const row of d2) {
              row.createdTime = moment(row.createdTime).format(
                "YYYY-MM-DD HH:mm"
              );
              row.dept = row.local + "/" + row.district + "/" + row.modum;
              if (token.memberNo == row.memberNo) {
                row.isEdit = true;
              } else {
                row.isEdit = false;
              }
              if (row.comment.includes("\n")) {
                row.comment = this.getContent(row.comment);
              }

              if (row.doubleComment && row.doubleComment.length > 0) {
                let i = 1;
                for (const item of row.doubleComment) {
                  item.content = this.getContent(item.content);
                  item.createdTime = moment(item.createdTime).format(
                    "YYYY-MM-DD HH:mm"
                  );
                  item.no2 = "⤷" + i++;
                  if (token.memberNo == item.memberNo) {
                    item.isEdit = true;
                  } else {
                    item.isEdit = false;
                  }
                }
              }
            }
          }
          this.tableData = d2;
          this.total = data2.total;
        });
      });
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 등록 모달
    doubleComment(row) {
      this.commentId = row.id;
      this.tableData2 = row.doubleComment;
      this.$refs.editModal2.handleAdd(row);
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    handleEdit2(row, row2, id) {
      this.commentId = row2.id;
      this.$refs.editModal2.handleEdit(row, row2.doubleComment, id);
    },
    // 등록 완료
    onAddDone() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 등록 완료
    onAddDone2() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone2() {
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    goList() {
      this.$router.push({
        name: "공지게시판",
      });
    },
    // 대댓글 삭제하기
    handleDelete2(row, index) {
      if (confirm("삭제하시겠습니까?")) {
        const data = {};
        row.doubleComment.splice(index, 1);
        data.doubleComment = row.doubleComment;
        data.type = "doubleComment";
        api
          .put(`service/myinformation/comment/${row.id}`, { data })
          .then(() => {
            this.getDataFromApi(1);
          });
      }
    },
    // 댓글 삭제하기
    handleDelete(row) {
      if (row.doubleComment.length > 0) {
        alert("대댓글이 있는 글은 삭제할 수 없습니다.");
        return;
      }
      if (confirm("삭제하시겠습니까?")) {
        const data = {};
        data.state = 0;
        api
          .put(`service/myinformation/comment/${row.id}`, { data })
          .then(() => {
            this.tableData = this.tableData.filter((r) => r.id !== row.id);

            const data = {};
            data.commentHit = this.commentHit - 1;
            api
              .put(`service/myinformation/noticeManage/${this.id}`, { data })
              .then(() => {});
            this.getDataFromApi(1);
          });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
#baseinput {
  margin-left: 10%;
  margin-right: 10%;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  background-color: rgba(151, 151, 157, 0.1);
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
p {
  word-break: break-all;
}
</style>
