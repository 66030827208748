<!-- 문의사항 관리 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-blue d-inline-block mb-0">나누기</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Search -->
                  <form class="form-inline mb-2">
                    <div class="col-sm-2">
                      <preset-select
                        placeholder="종류"
                        v-model="query.eduName"
                        :choices="eduNames"
                      />
                    </div>

                    <div class="col-sm-2" v-if="isManager">
                      <input
                        type="text"
                        class="form-control col"
                        placeholder="그룹"
                        v-model="query.groupName"
                      />
                    </div>
                    <div class="col-sm-2">
                      <input
                        type="text"
                        class="form-control col"
                        placeholder="회원"
                        v-model="query.memName"
                      />
                    </div>
                    <div class="col-sm-3">
                      <input
                        type="date"
                        class="form-control col"
                        placeholder="날짜(YYYY-MM-DD)"
                        v-model="query.insertDate"
                      />
                    </div>
                    <div class="col">
                      <base-button
                        class="btn-sm"
                        type="secondary"
                        @click.prevent="getDataFromApi(1)"
                        >검색</base-button
                      >
                    </div>
                  </form>
                  <!-- End Search -->
                </div>
              </div>
            </div>
            <div class="col text-right" v-if="!isJeon">
              <!-- New Button -->
              <base-button type="primary" class="btn-sm" @click="handleAdd"
                >+나누기 등록</base-button
              >
              <!-- End New Button -->
            </div>
            <!-- End Search, New Button -->
            <!-- Preview Modal -->
            <modal v-model:show="showPreviewModal" size="lg">
              <template v-slot:header>
                <h6 class="modal-title">
                  <div><p>나누기 내용</p></div>
                </h6>
              </template>
              <div class="text-left" v-html="content"></div>
              <template v-slot:footer>
                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </template>
            </modal>
            <!-- End Preview Modal -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="순서" sortable min-width="80px" prop="no">
              </el-table-column>
              <el-table-column
                v-if="!isPC"
                label="작성일시"
                sortable
                min-width="150px"
                prop="insertDate"
              >
                <template v-slot="{ row }">
                  <span class="clickable" @click="showPreview(row)">
                    <div class="font-weight-600">
                      {{ row.insertDate }}
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!isPC"
                label="이름"
                sortable
                min-width="100px"
                prop="memName"
              >
              </el-table-column>

              <el-table-column
                label="제목"
                sortable
                min-width="100px"
                prop="eduName"
              >
              </el-table-column>
              <el-table-column
                label="그룹"
                sortable
                min-width="100px"
                prop="groupName"
              >
              </el-table-column>
              <el-table-column
                v-if="isJeon"
                label="회원번호"
                sortable
                min-width="100px"
                prop="memId"
              >
              </el-table-column>
              <el-table-column
                v-if="isPC"
                label="이름"
                sortable
                min-width="100px"
                prop="memName"
              >
              </el-table-column>

              <el-table-column
                v-if="isPC"
                label="작성일시"
                sortable
                min-width="150px"
                prop="insertDate"
              >
                <template v-slot="{ row }">
                  <span class="clickable" @click="showPreview(row)">
                    <div class="font-weight-900 text-blue">
                      {{ row.insertDate }}
                    </div>
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="전송일시"
                sortable
                min-width="100px"
                prop="transDate"
              >
              </el-table-column>

              <el-table-column
                label="action"
                min-width="150px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex" v-if="row.edit">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <!-- <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                     삭제 
                  </base-button> -->
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import PresetSelect from "@/components/Inputs/PresetSelect";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/ShareEditModal.vue";
import Modal from "@/components/Modal";
import * as api from "@/api";
import moment from "moment";
import { LOCAL, getLocalNames } from "@/consts/map";
import { Auth } from "@/api";
import util from "@/util/util";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
    PresetSelect,
  },
  // 데이터 객체 추가
  data() {
    return {
      LOCAL,
      getLocalNames,
      districts: [],
      tableData: [], // 레코드셋
      query: {
        // 검색 쿼리
        eduName: "",
        groupName: "",
        memName: "",
        insertDate: "",
        groupChief: "",
        mentor: "",
      },
      newData: {
        // 새 등록 데이터
        id: 0,
        question: "",
        questPart: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      previewTitle: "",
      previewBody: "",
      showPreviewModal: false,
      isJeon: true,
      isManager: false,
      edit: false,
      isPC: true,
      eduNames: [],
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (b < a) {
        this.isPC = true;
      } else {
        this.isPC = false;
      }
      this.loading = true;
      const token = Auth.tokenContent;

      if (this.query.groupName) {
        let temp = this.query.groupName.slice(0, -3);
        let temp2 = this.query.groupName.substring(
          this.query.groupName.length - 3,
          this.query.groupName.length
        );
        if (this.query.groupName.includes("0")) {
          temp = this.query.groupName.slice(0, -4);
          temp2 = this.query.groupName.substring(
            this.query.groupName.length - 4,
            this.query.groupName.length
          );
        }
        this.query.groupName = temp.trim() + " " + temp2;
      }

      const url2 = `service/myinformation/edu?email=${token.email}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data;

        const groupChief = [];
        const eduStudent = [];
        const mentorGroup = [];
        for (const member of data2.data) {
          for (const student of member.groups) {
            if (groupChief.indexOf(student.gr_mem_id) < 0) {
              groupChief.push(student.gr_mem_id);
            }
            if (mentorGroup.indexOf(student.dt_mem_id) < 0) {
              mentorGroup.push(student.dt_mem_id);
            }
            for (const item of student.students) {
              if (eduStudent.indexOf(item) < 0) {
                eduStudent.push(item);
              }
            }
          }
        }
        let url3 = "";
        let type = "";
        let eduOrder = "";
        if (eduStudent.includes(token.memberNo)) {
          this.isShare = true;
          this.isJeon = false;
          this.isManager = false;
          type = "student";
          url3 = `service/myinformation/eduStudent?memberNo=${token.memberNo}`;
        } else if (mentorGroup.includes(token.memberNo)) {
          this.isJeon = true;
          this.isManager = false;
          type = "mentor";
          url3 = `service/myinformation/eduStudent?mentor=${token.memberNo}`;
        } else if (groupChief.includes(token.memberNo)) {
          this.isJeon = false;
          this.isManager = false;
          type = "groupChief";
          url3 = `service/myinformation/eduStudent?groupChief=${token.memberNo}`;
        } else {
          this.isJeon = true;
          this.isManager = false;
          type = "groupChief";
          url3 = `service/myinformation/eduStudent?groupChief=${token.memberNo}`;
        }
        if (sessionStorage.getItem("shareRole2") == "manager") {
          this.isJeon = false;
        }

        api.get(url3).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          if (sessionStorage.getItem("shareRole") === "student") {
            eduOrder = data2.data[0].eduOrder;
            sessionStorage.setItem("eduOrder", eduOrder);
          }

          if (data2.rows > 0) {
            if (!this.query.groupName) {
              if (type === "mentor" || type === "groupChief") {
                this.query.groupName = "";
              } else {
                this.query.groupName = data2.data[0].groupName;
              }
            }
          }

          if (this.query.groupName === "전체") {
            this.query.groupName = "";
          }
          let url2 = `service/myinformation/share?bound=${this.perPage}&page=${page}&insertDate=${this.query.insertDate}&memName=${this.query.memName}&eduName=${this.query.eduName}&groupName=${this.query.groupName}`;
          if (type === "groupChief") {
            url2 = `service/myinformation/share?bound=${this.perPage}&page=${page}&groupChief=${token.memberNo}&insertDate=${this.query.insertDate}&memName=${this.query.memName}&eduName=${this.query.eduName}&groupName=${this.query.groupName}`;
          } else if (type === "mentor") {
            url2 = `service/myinformation/share?bound=${this.perPage}&page=${page}&mentor=${token.memberNo}&insertDate=${this.query.insertDate}&memName=${this.query.memName}&eduName=${this.query.eduName}&groupName=${this.query.groupName}`;
          } else if (type === "student") {
            url2 = `service/myinformation/share?bound=${this.perPage}&page=${page}&insertDate=${this.query.insertDate}&memName=${this.query.memName}&eduName=${this.query.eduName}&groupName=${this.query.groupName}&eduOrder=${eduOrder}`;
          }
          if (sessionStorage.getItem("shareRole2") === "manager") {
            if (!this.query.groupName) {
              this.query.groupName = "";
            }
            this.isManager = true;
            url2 = `service/myinformation/share?page=${page}&insertDate=${this.query.insertDate}&memName=${this.query.memName}&eduName=${this.query.eduName}&groupName=${this.query.groupName}`;
          }

          api.get(url2).then((response) => {
            if (!api.isSuccess(response)) {
              return;
            }
            const data = response.data;
            const d = data.data;
            if (data.rows > 0) {
              for (const row of d) {
                if (token.memberNo == row.memId) {
                  row.edit = true;
                } else {
                  row.edit = false;
                }
                row.insertDate = moment(row.insertDate).format("YYYY-MM-DD");
                if (row.transDate) {
                  row.transDate = moment(row.transDate).format("YYYY-MM-DD");
                }
                if (row.content) {
                  row.content = util.getDecrypt(row.content);
                  row.resultContent = row.content.split("\n").join("<br>");
                }
              }
            }
            this.tableData = d;
            this.total = data.total;
            this.getDataFromApi2();
          });
        });
      });
    },
    showPreview(row) {
      this.content = row.resultContent;
      this.showPreviewModal = true;
    },

    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone() {
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    getDataFromApi2() {
      let eduOrder = "";
      if (sessionStorage.getItem("shareRole") === "student") {
        eduOrder = sessionStorage.getItem("eduOrder");
      }
      const url2 = `service/myinformation/edu2?eduOrder=${eduOrder}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        let edu = [];
        this.eduNames = [];
        this.eduNames.push(["", "전체"]);
        if (data.rows > 0) {
          for (const row of data.data) {
            edu.push(row.name);
            edu.push(row.name);
            this.eduNames.push(edu);
            edu = [];
          }
        }
      });
    },
    // 삭제하기
    // handleDelete(row) {
    //   if (confirm("삭제하시겠습니까?")) {
    //     // api.delete_(`service/myinformation/share/${row.id}`).then(() => {
    //     //   this.tableData = this.tableData.filter((r) => r.id !== row.id);
    //     // });
    //     const data = { state: 0 };
    //     api.put(`service/myinformation/share/${row.id}`, { data }).then(() => {
    //       this.tableData = this.tableData.filter((r) => r.id !== row.id);
    //     });
    //   }
    // },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
