<template>
  <el-select
    :modelValue="modelValue"
    @change="update"
    :placeholder="placeholder"
    :disabled="disabled"
  >
    <el-option
      v-for="[val, label] in computedChoices"
      :key="val"
      class="select-primary"
      :label="label"
      :value="val"
    />
  </el-select>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";

export default {
  name: "PresetSelect",
  componentName: "PresetSelect",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    choices: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    allLabel: {
      type: String,
      required: false,
      default: null,
    },
    allValue: {
      type: String,
      reuqired: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      reuqired: false,
      default: false,
    },
  },
  computed: {
    computedChoices() {
      const choices = this.choices.slice();
      if (this.allLabel !== null) {
        choices.unshift([this.allValue, this.allLabel]);
      }
      return choices;
    },
  },
  methods: {
    update(newValue) {
      this.$emit("update:modelValue", newValue);
      this.$emit("change", newValue);
    },
  },
};
</script>
