<!-- 문의사항 관리 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-blue d-inline-block mb-0">정토회 일정</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <iframe
                    src="https://calendar.google.com/calendar/embed?src=jungto.org_htbism590302i3cklcihc8mt9o%40group.calendar.google.com&ctz=Asia%2FSeoul"
                    style="border: 0"
                    width="800"
                    height="600"
                    frameborder="0"
                    scrolling="no"
                  ></iframe>
                  <!-- End Search -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->

            <!-- List -->

            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import * as api from "@/api";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  // 데이터 객체 추가
  data() {
    return {
      total: 0, // 총 레코드 수
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi() {
      const url = `service/myinformation/statics`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        this.total = 0;
        if (data.rows > 0) {
          for (const row of d) {
            row.dept = row.local + "/" + row.district + "/" + row.modum;
            this.total = this.total + row.hit;
          }
        }
        this.tableData = d;
        this.total2 = data.total;
      });
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
