import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Layout/AuthLayout";

// Dashboard pages
import Main from "@/views/Service/Main";
import Main2 from "@/views/Service/Main3";

// Pages
import MyInfo from "@/views/Service/MyInfo.vue";
import Question from "@/views/Service/Question.vue";
import QuestionManage from "@/views/Service/QuestionManage.vue";
import Manager from "@/views/Service/Manager.vue";
import NoticeManage from "@/views/Service/NoticeManage/List.vue";
import NoticeService from "@/views/Service/NoticeService/List.vue";
import Comment from "@/views/Service/NoticeManage/List2.vue";
import Comment2 from "@/views/Service/NoticeService/List2.vue";
import Calendar from "@/views/Service/NoticeService/Calendar.vue";
import Calendar2 from "@/views/Service/NoticeManage/Calendar.vue";
import Master from "@/views/Service/Master2.vue";
import Share from "@/views/Service/ShareList.vue";
import EduStudent from "@/views/Service/EduStudent.vue";
import Statics from "@/views/Service/Statics.vue";
import Entry from "@/views/Service/Entry.vue";
import Guest from "@/views/Service/Guest.vue";
import Schedule from "@/views/Service/Schedule.vue";
import Schedule2 from "@/views/Service/Schedule2.vue";
import Login from "@/views/Service/Login2.vue";
import NotFound from "@/views/Template/GeneralViews/NotFoundPage.vue";
import Argon from "@/views/Template/Dashboard/Dashboard.vue";
import { Auth } from "@/api";

// {
//   path: "",
//   name: "공지 게시판",
//   components: { default: NoticeService },
//   meta: { loginRequired: true },
// },
// {
//   path: "",
//   name: "홈",
//   components: { default: Main2 },
//   meta: { loginRequired: true },
// },
const routes = [
  {
    path: "/",
    name: "Home",
    component: DashboardLayout,
    children: [
      {
        path: "/entry",
        name: "회관방문자 초대",
        components: { default: Entry },
        meta: { loginRequired: true },
      },
      {
        path: "/guest",
        name: "회관방문자 초대 - 손님등록",
        components: { default: Guest },
        meta: { loginRequired: true },
      },
      {
        path: "",
        name: "공지 게시판",
        components: { default: NoticeService },
        meta: { loginRequired: true },
      },
      {
        path: "/noticeService",
        name: "공지게시판",
        components: { default: NoticeService },
        meta: { loginRequired: true },
      },
      {
        path: "/master",
        name: "Master",
        components: { default: Master },
        meta: { loginRequired: true },
      },
      {
        path: "/calendar",
        name: "공지게시판(월별보기)",
        components: { default: Calendar },
        meta: { loginRequired: true },
      },
      {
        path: "/calendar2",
        name: "공지게시판관리(월별보기)",
        components: { default: Calendar2 },
        meta: { loginRequired: true },
      },
      {
        path: "/schedule",
        name: "정토회일정(iframe)",
        components: { default: Schedule },
        meta: { loginRequired: true },
      },
      {
        path: "/schedule2",
        name: "정토회일정",
        components: { default: Schedule2 },
        meta: { loginRequired: true },
      },
      {
        path: "/main",
        name: "note",
        components: { default: Main },
        meta: { loginRequired: true },
      },
      {
        path: "/main2",
        name: "pc",
        components: { default: Main2 },
        meta: { loginRequired: true },
      },
      {
        path: "/question",
        name: "내 문의사항",
        components: { default: Question },
        meta: { loginRequired: true },
      },
      {
        path: "/statics",
        name: "접속통계",
        components: { default: Statics },
        meta: { loginRequired: true },
      },
      {
        path: "/questionManage",
        name: "문의사항관리",
        components: { default: QuestionManage },
        meta: { loginRequired: true },
      },
      {
        path: "/noticeManage",
        name: "공지게시판관리",
        components: { default: NoticeManage },
        meta: { loginRequired: true },
      },
      {
        path: "/comment",
        name: "댓글쓰기",
        components: { default: Comment },
        meta: { loginRequired: true },
      },
      {
        path: "/comment2",
        name: "댓글 쓰기",
        components: { default: Comment2 },
        meta: { loginRequired: true },
      },
      {
        path: "/manager",
        name: "관리자",
        components: { default: Manager },
        meta: { loginRequired: true },
      },
      {
        path: "/share",
        name: "나누기",
        components: { default: Share },
        meta: { loginRequired: true },
      },
      {
        path: "/eduStudent",
        name: "교육생목록조회",
        components: { default: EduStudent },
        meta: { loginRequired: true },
      },
      {
        path: "/my-info",
        name: "내정보",
        components: { default: MyInfo },
        meta: { loginRequired: true },
      },
    ],
  },

  {
    path: "/logout",
    component: AuthLayout,
    name: "로그아웃",
    children: [
      {
        path: "",
        name: "",
        components: { default: Login },
      },
    ],
  },
  {
    path: "/argon",
    component: DashboardLayout,
    name: "argon",
    children: [
      {
        path: "",
        name: "argon",
        components: { default: Argon },
      },
    ],
  },
  {
    path: "/login",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "Login",
        components: { default: Login },
      },
      { path: "*", component: NotFound },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from_, next) => {
  if (to.meta.loginRequired && !Auth.isLoggedIn) {
    sessionStorage.setItem("fullPath", to.fullPath);
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
