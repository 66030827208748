<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">관리자</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row">
          <label class="form-control-label ml-0">지부 *</label>
        </div>

        <div class="row">
          <preset-select
            label="지부"
            required
            name="local"
            placeholder="지부"
            v-model="newData.local"
            :choices="LOCAL.map((x) => [x, `${x}`])"
          />
        </div>

        <div class="row mt-3">
          <base-input
            type="text"
            required
            label="이름"
            name="name"
            placeholder="이름"
            v-model="newData.name"
          />
        </div>
        <div class="row">
          <base-input
            type="number"
            required
            label="회원번호"
            name="회원번호"
            placeholder="회원번호"
            v-model="newData.memberNo"
          />
        </div>
        <div class="row">
          <base-input
            type="text"
            required
            label="email"
            name="email"
            placeholder="email"
            v-model="newData.email"
          />
        </div>
        <div class="row">
          <label class="form-control-label ml-0">소임 *</label>
        </div>
        <div class="row mb-3">
          <preset-radio
            label="소임"
            v-model="newData.role"
            :choices="[
              ['관리자', '관리자'],
              ['지부팀장', '지부팀장'],
              ['지부회원담당', '지부회원담당'],
              ['', '역할없음'],
            ]"
          />
        </div>
        <div class="row">
          <label class="form-control-label ml-0">나누기 역할</label>
        </div>
        <div class="row mb-3">
          <preset-radio
            label="나누기 역할"
            v-model="newData.shareRole"
            :choices="[
              ['관리자', '관리자'],
              ['', '역할없음'],
            ]"
          />
        </div>
        <div class="row">
          <label class="form-control-label ml-0">공지 게시판 역할</label>
        </div>
        <div class="row mb-3">
          <preset-radio
            label="나누기 역할"
            v-model="newData.noticeRole"
            :choices="[
              ['관리자', '관리자'],
              ['', '역할없음'],
            ]"
          />
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave">{{
            modalMode
          }}</base-button>
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import { LOCAL } from "@/consts/class";

export default {
  components: {
    Modal,
    PresetSelect,
    PresetRadio,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      LOCAL,
      newData: {
        local: "",
        name: "",
        email: "",
        role: "",
        memberNo: "",
        noticeRole: "",
        shareRole: "",
      },
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.put(`service/myinformation/manager/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    onAdd() {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.post(`service/myinformation/manager`, { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.name = "";
      this.newData.memberNo = "";
      this.newData.local = "";
      this.newData.email = "";
      this.newData.role = "";
      this.newData.shareRole = "";
      this.newData.noticeRole = "";
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.loadModal(`service/myinformation/manager/${row.id}`, row, (data) => {
        return {
          name: data.name,
          memberNo: data.memberNo,
          email: data.email,
          local: data.local,
          role: data.role,
          shareRole: data.shareRole,
          noticeRole: data.noticeRole,
        };
      });
    },
  },
};
</script>
