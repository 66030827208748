<!-- 문의사항 관리 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-blue d-inline-block mb-0">교육생 목록 조회</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Search -->
                  <form class="form-inline mb-2">
                    <div class="col-sm-2">
                      <preset-select
                        placeholder="종류"
                        v-model="query.eduName"
                        :choices="eduNames"
                      />
                    </div>
                    <div class="col-sm-2" v-if="isJeon">
                      <input
                        type="text"
                        class="form-control col"
                        placeholder="그룹"
                        v-model="query.groupName"
                      />
                    </div>
                    <div class="col-sm-2" v-if="isJeon">
                      <input
                        type="text"
                        class="form-control col"
                        placeholder="그룹장(회원번호)"
                        v-model="query.groupChief"
                      />
                    </div>
                    <div class="col-sm-2" v-if="isJeon">
                      <input
                        type="text"
                        class="form-control col"
                        placeholder="담당법사(회원번호)"
                        v-model="query.mentor"
                      />
                    </div>
                    <div class="col-sm-2">
                      <input
                        type="text"
                        class="form-control col"
                        placeholder="회원이름"
                        v-model="query.memName"
                      />
                    </div>

                    <div class="col">
                      <base-button
                        class="btn-sm"
                        type="secondary"
                        @click.prevent="getDataFromApi(1)"
                        >검색</base-button
                      >
                    </div>
                  </form>
                  <!-- End Search -->
                </div>
              </div>
            </div>
            <div class="col text-right" v-if="!isJeon">
              <!-- New Button -->
              <base-button type="primary" class="btn-sm" @click="handleAdd"
                >+나누기 등록</base-button
              >
              <!-- End New Button -->
            </div>
            <!-- End Search, New Button -->
            <!-- Preview Modal -->
            <modal v-model:show="showPreviewModal" size="lg">
              <template v-slot:header>
                <h6 class="modal-title">
                  <div><p>나누기 내용</p></div>
                </h6>
              </template>
              <div class="text-left" v-html="content"></div>
              <template v-slot:footer>
                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </template>
            </modal>
            <!-- End Preview Modal -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="순서" sortable min-width="80px" prop="no">
              </el-table-column>
              <el-table-column
                label="교육아이디"
                sortable
                min-width="100px"
                prop="eduId"
              >
              </el-table-column>
              <el-table-column
                label="교육명"
                sortable
                min-width="100px"
                prop="eduName"
              >
              </el-table-column>
              <el-table-column
                label="그룹"
                sortable
                min-width="100px"
                prop="groupName"
              >
              </el-table-column>
              <el-table-column
                v-if="isJeon"
                label="회원번호"
                sortable
                min-width="100px"
                prop="memberNo"
              >
              </el-table-column>
              <el-table-column
                label="이름"
                sortable
                min-width="100px"
                prop="memName"
              >
              </el-table-column>
              <el-table-column
                label="그룹장"
                sortable
                min-width="100px"
                prop="groupChief"
              >
              </el-table-column>
              <el-table-column
                label="담당법사"
                sortable
                min-width="100px"
                prop="mentor"
              >
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import PresetSelect from "@/components/Inputs/PresetSelect";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/ShareEditModal.vue";
import Modal from "@/components/Modal";
import * as api from "@/api";
import moment from "moment";
import { LOCAL, getLocalNames } from "@/consts/map";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
    PresetSelect,
  },
  // 데이터 객체 추가
  data() {
    return {
      LOCAL,
      getLocalNames,
      districts: [],
      tableData: [], // 레코드셋
      query: {
        // 검색 쿼리
        eduName: "",
        groupName: "",
        memName: "",
        insertDate: "",
        groupChief: "",
        mentor: "",
      },
      newData: {
        // 새 등록 데이터
        id: 0,
        question: "",
        questPart: "",
      },
      perPage: 10, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      previewTitle: "",
      previewBody: "",
      showPreviewModal: false,
      isJeon: true,
      eduNames: [],
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      this.loading = true;
      const url2 = `service/myinformation/edu2`;
      api.get(url2).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        this.eduNames = [];
        const edu = ["", "전체"];
        this.eduNames.push(edu);
        const data2 = response2.data;
        if (data2.rows > 0) {
          for (const row of data2.data) {
            const edu = [row.name, row.name];
            this.eduNames.push(edu);
          }
        }
      });
      if (sessionStorage.getItem("shareRole") === "student") {
        this.isJeon = false;
      }

      if (sessionStorage.getItem("shareRole") === "관리자") {
        if (!this.query.groupName) {
          this.query.groupName = "";
        }
      }
      const url = `service/myinformation/eduStudent?page=${page}&bound=${this.perPage}&mentor=${this.query.mentor}&groupChief=${this.query.groupChief}&memName=${this.query.memName}&eduName=${this.query.eduName}&groupName=${this.query.groupName}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.insertDate = moment(row.insertDate).format("YYYY-MM-DD");
            row.transDate = moment(row.transDate).format("YYYY-MM-DD");
            if (row.resultContent) {
              row.resultContent = row.resultContent.split("\n").join("<br>");
            }
          }
        }
        this.tableData = d;
        this.total = data.total;
      });
    },
    showPreview(row) {
      this.content = row.content;
      this.showPreviewModal = true;
    },

    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone() {
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`service/myinformation/share/${row.id}`).then(() => {
          this.tableData = this.tableData.filter((r) => r.id !== row.id);
        });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
