const crypto = require("crypto");

exports.splitURL = function (strURL) {
  return strURL
    .split("?")[0]
    .split("/")
    .filter(function (n) {
      return n === " " || n;
    });
};

exports.getQueryString = function (strURL) {
  let arrURL = strURL.split("?");

  if (arrURL.length > 0) {
    return arrURL[1];
  } else return "";
};

exports.toUpperCaseFirst = function (strWord) {
  if (strWord.length > 0) {
    let char = strWord.substr(0, 1);
    return strWord.replace(char, char.toUpperCase());
  } else return "";
};

//convert JSON, XML ------------------------------------------------------------------
exports.jsonToArray = function (json) {
  let result = [];
  let keys = Object.keys(json);
  keys.forEach(function (key) {
    result.push(json[key]);
  });
  return result;
};

exports.setObject = function (object, value, keyname) {
  if (object == null) {
    object = value;
  } else {
    object[keyname] = value;
  }
};

exports.deleteObject = function (object, keyname) {
  if (object !== null) {
    delete object[keyname];
  }
};

exports.addArray = function (array, value) {
  array.push(value);
};

exports.setArray = function (array, value) {
  array = value;
};

exports.deleteArray = function (array, value) {
  return array.filter(function (ele) {
    return ele != value;
  });
};

exports.addToJSON = function (target, keyname, value) {
  //Json to Object
  var obj = JSON.parse(target);

  if (Array.isArray(obj[keyname])) {
    obj.keyname.push(value);
  } else {
    obj[keyname] = value;
  }

  return JSON.stringify(obj);
};

exports.setToJSON = function (target, keyname, value) {
  //Json to Object
  var obj = JSON.parse(target);
  obj[keyname] = value;
  return JSON.stringify(obj);
};

exports.deleteJSON = function (target, keyname) {
  //Json to Object
  var obj = JSON.parse(target);
  delete obj[keyname];
  return JSON.stringify(obj);
};

exports.getJSONValue = function (target, keyname) {
  //Json to Object
  var obj = JSON.parse(target);
  return obj[keyname];
};

exports.replaceKey = function (obj, targetKey, newKey) {
  obj[newKey] = obj[targetKey];
  delete obj[targetKey];
  return obj;
};

exports.replaceDocsKey = function (docs, targetKey, newKey) {
  for (let row of docs) {
    this.replaceKey(row._doc, targetKey, newKey);
  }
  return docs;
};

exports.deleteDocsKey = function (docs, targetKey) {
  for (let row of docs) {
    delete row[targetKey];
  }
  return docs;
};

exports.getFormatDate = function (date, hour, type) {
  if (!date || date === "") {
    date = new Date();
  } else {
    date = new Date(date);
  }

  if (hour != null || hour > 0) {
    date.setHours(date.getHours() + hour);
  }

  let month = 1 + date.getMonth(); //M
  let day = date.getDate(); //d

  if (type === "kmd") {
    return month + "월 " + day + "일";
  } else if (type === "kymd") {
    return date.getFullYear() + "년 " + month + "월 " + day + "일";
  } else if (type === "kt") {
    return (
      month +
      "월 " +
      day +
      "일 " +
      date.getHours() +
      "시 " +
      date.getMinutes() +
      "분"
    );
  } else if (type === "time") {
    month = month >= 10 ? month : "0" + month; //month 두자리로 저장
    day = day >= 10 ? day : "0" + day; //day 두자리로 저장

    return date.getFullYear() + "-" + month + "-" + day + " 00:00:00";
  } else {
    month = month >= 10 ? month : "0" + month; //month 두자리로 저장
    day = day >= 10 ? day : "0" + day; //day 두자리로 저장

    return date.getFullYear() + "-" + month + "-" + day;
  }
};

// 단방향 암호화
exports.getEncrypt = function (str) {
  return crypto
    .createHmac("sha1", process.env.SECRET_KEY)
    .update(str)
    .digest("hex"); // sha512 변경 요
};

// 암호화
// exports.getEncryptCipher = function (str) {
//     let cipher = crypto.createCipher('aes-256-ecb', process.env.SECRET_KEY);
//     return cipher.update(str, 'utf-8', 'base64') + cipher.final('base64');
// };
// const SECRET_KEY = 'AAAAAAA000CCCCC999999FFFFBBBBBB1'
// // // 복호화
// exports.getDecrypt = function (str) {
//     let cipher = crypto.createDecipher('aes-256-ecb', SECRET_KEY);
//     return cipher.update(str, 'base64', 'utf-8') + cipher.final('utf-8');
// };

const IV_LENGTH = 16; // For AES, this is always 16
exports.getEncryptCipher = function (text) {
  let iv = Buffer.from(crypto.randomBytes(IV_LENGTH))
    .toString("hex")
    .slice(0, IV_LENGTH);
  let cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from("Q674MFV904MDF0P4F0439F027SDL60PP"),
    iv
  );
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv + ":" + encrypted.toString("hex");
};

exports.getDecrypt = function (text) {
  let textParts = [];
  textParts = text.includes(":") ? text.split(":") : [];
  let iv = Buffer.from(textParts.shift() || "", "binary");
  let encryptedText = Buffer.from(textParts.join(":"), "hex");
  let decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from("Q674MFV904MDF0P4F0439F027SDL60PP"),
    iv
  );
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

// 빈 값 체크
exports.isEmpty = function (value) {
  if (
    value === "" ||
    value == null ||
    value == undefined ||
    (value != null && typeof value === "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

// 빈 값 교체
exports.ifEmpty = function (value, replace) {
  if (
    value === "" ||
    value == null ||
    value == undefined ||
    (value != null && typeof value === "object" && !Object.keys(value).length)
  ) {
    return replace;
  } else {
    return value;
  }
};

exports.makeRegexStartWith = function (temp) {
  return {
    $regex: new RegExp("^" + escapeRegExp(temp)),
    $options: "i",
  };
};

exports.makeRegexContains = function (temp) {
  return {
    $regex: new RegExp(escapeRegExp(temp)),
    $options: "i",
  };
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const DAY_NAMES = {
  ko: ["일", "월", "화", "수", "목", "금", "토"],
  en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

exports.getDayNumber = function (dayName, lang) {
  return DAY_NAMES[lang].indexOf(dayName);
};

exports.getDayName = function (dayNumber, lang) {
  return DAY_NAMES[lang][dayNumber];
};

exports.getTwoDigits = function (number) {
  return (100 + number).toString().substr(1, 2);
};
