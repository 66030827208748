<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">댓글 {{ modalModeText }}</h6>
    </template>
    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row">
          <label class="form-control-label">댓글</label>
        </div>
        <div :key="htmlEditorKey">
          <html-editor v-model="newData.comment" />
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin";
import * as api from "@/api";
import HtmlEditor from "@/components/Inputs/HtmlEditor.vue";

export default {
  components: {
    Modal,
    HtmlEditor,
  },
  props: ["noticeId"],
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        id: 0,
      },
      htmlEditorKey: 0,
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    // 등록하기
    onAdd() {
      const url = `service/myinformation/noticeManage/${this.noticeId}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        let row = response.data.data;
        const data = {};
        if (row.commentHit) {
          data.commentHit = row.commentHit + 1;
        } else {
          data.commentHit = 1;
        }
        api
          .put(`service/myinformation/noticeManage/${row.id}`, { data })
          .then(() => {});
      });

      const data = JSON.parse(JSON.stringify(this.newData));
      data.noticeId = this.noticeId;
      api.post("service/myinformation/comment", { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.put(`service/myinformation/comment/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.comment = "";
      this.htmlEditorKey++;
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.loadModal(`service/myinformation/comment/${row.id}`, row, (data) => {
        this.newData = data;
        this.htmlEditorKey++;
        return {
          id: data.id,
          comment: data.comment,
        };
      });
    },
  },
};
</script>
