<template>
  <div>
    <div class="authentification">
      <h2>관리자 화면</h2>
    </div>
    <hr />
    <div class="text-center">
      <button v-if="authorized" @click="getData">정토회 일정 업데이트</button>
    </div>
  </div>
</template>

<script>
const CLIENT_ID =
  "581450239722-8qlkus1tl7leuvahfu6dnqdngg4u3vsb.apps.googleusercontent.com";
const API_KEY = "AIzaSyDe75dgtfK7gGldhO4BRJQ8-Djuh2tues8";
// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/calendar";
import gapi from "vue-gapi";
export default {
  data() {
    return {
      items: undefined,
      api: undefined,
      authorized: true,
    };
  },

  created() {
    this.api = gapi;
    this.handleClientLoad();
  },

  methods: {
    /**
     *  On load, called to load the auth2 library and API client library.
     */
    handleClientLoad() {
      this.api.load("client:auth2", this.initClient);
    },

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    initClient() {
      let vm = this;
      vm.api.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          // Listen for sign-in state changes.
          vm.api.auth2.getAuthInstance().isSignedIn.listen(vm.authorized);
        });
    },

    /**
     *  Sign in the user upon button click.
     */
    handleAuthClick() {
      Promise.resolve(this.api.auth2.getAuthInstance().signIn()).then(() => {
        this.authorized = true;
      });
    },

    /**
     *  Sign out the user upon button click.
     */
    handleSignoutClick() {
      Promise.resolve(this.api.auth2.getAuthInstance().signOut()).then(() => {
        this.authorized = false;
      });
    },

    /**
     * Print the summary and start datetime/date of the next ten events in
     * the authorized user's calendar. If no events are found an
     * appropriate message is printed.
     */
    getData() {
      let vm = this;
      var event = {
        summary: "Google I/O 2015",
        location: "800 Howard St., San Francisco, CA 94103",
        description: "A chance to hear more about Google's developer products.",
        start: {
          date: new Date("2023-01-15"),
        },
        end: {
          date: new Date("2023-01-16"),
        },
      };

      vm.api.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
      });
      //  vm.api.client.calendar.events
      //  .list({
      //     calendarId:
      //       "primary",
      //     showDeleted: false,
      //     singleEvents: true,
      //     maxResults: 3,
      //     timeMax: "2023-01-30T00:00:00Z",
      //     timeMin: "2023-01-01T00:00:00Z",
      //     orderBy: "startTime",
      //   })
      //   .then((response) => {
      //    alert("aa" + JSON.stringify(response));
      //   });
      alert("aa" + JSON.stringify(event));
    },
  },
};
</script>
<style>
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1em;
}

.authentification {
  margin: 20px;
  text-align: center;
}

hr {
  border: 1px solid black;
  margin: 30px;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  overflow-x: auto;
}

.string {
  color: green;
}

.number {
  color: purple;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: black;
}
</style>
