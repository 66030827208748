<script>
import * as api from "@/api";

const ADD = "추가";
const EDIT = "수정";

export default {
  data() {
    return {
      modal: false,
      modalMode: ADD, // ADD | EDIT
      currentRow: null, // EDIT 모드일 경우, 수정되고 있는 레코드 데이터
      loadingModal: false, // EDIT 모드, 수정할 데이터 로딩중임을 나타냄 (아직 모달 뜨기전)
    };
  },
  methods: {
    onSave() {
      if (this.modalMode === EDIT) {
        this.onEdit(this.currentRow);
      } else {
        this.onAdd();
      }
    },
    _beginLoadingModal(row) {
      this.currentRow = row;
      this.loadingModal = true;
    },
    _endLoadingModal() {
      this.loadingModal = false;
    },
    showAddModal() {
      this.modalMode = ADD;
      this.modal = true;
    },
    showEditModal(row) {
      if (row !== undefined) {
        this.currentRow = row;
      }
      this.modalMode = EDIT;
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    loadModal(url, row, dataMapper) {
      this._beginLoadingModal(row);
      api
        .get(url)
        .then((response) => {
          if (api.isSuccess(response)) {
            const data = response.data.data;
            //dataMapper(data);
            this.newData = Object.assign({}, dataMapper(data));
            this.showEditModal();
          }
        })
        .finally(() => {
          this._endLoadingModal();
        });
    },
  },
};
export { ADD, EDIT };
</script>
