// 지부
export const LOCAL = [
  "전체",
  "서울제주지부",
  "강원경기동부지부",
  "인천경기서부지부",
  "대전충청지부",
  "광주전라지부",
  "대구경북지부",
  "부산울산지부",
  "경남지부",
  "해외지부",
  "국제특별지부",
  "청년특별지부",
  "공동체특별지부",
  "행복운동특별본부",
];

//지부: 지회 mapping
export const LOCAL_NAMES = {
  서울제주지부: [
    "관악지회",
    "구로지회",
    "노원지회",
    "서대문지회",
    "서초지회",
    "성동지회",
    "송파지회",
    "양천지회",
    "제주지회",
  ],
  강원경기동부지부: [
    "수원지회",
    "화성지회",
    "경기광주지회",
    "성남지회",
    "용인지회",
    "남양주지회",
    "원주지회",
  ],
  인천경기서부지부: [
    "인천지회",
    "일산지회",
    "안양지회",
    "광명지회",
    "부천지회",
  ],
  대전충청지부: ["대전지회", "세종지회", "천안지회", "청주지회", "충주지회"],
  광주전라지부: ["서광주지회", "동광주지회", "전주지회"],
  대구경북지부: [
    "구미지회",
    "달서지회",
    "동대구지회",
    "수성지회",
    "경주지회",
    "포항지회",
  ],
  부산울산지부: [
    "동래지회",
    "금정지회",
    "해운대지회",
    "수영지회",
    "사하지회",
    "남울산지회",
    "중울산지회",
  ],
  경남지부: ["창원지회", "김해지회", "거제지회", "진주지회"],
  공동체지부: ["문경지회", "서울지회"],
  해외지부: ["아태유럽지회", "북미지회"],
  국제지부: ["아태유럽지회", "북미지회"],
  행복운동특별본부: [
    "강원경기동부지회",
    "강원경기남부지회",
    "강원경기북부지회",
    "경남지회",
    "광주전라지회",
    "대구경북동부지회",
    "대구경북서부지회",
    "대전충청남부지회",
    "대전충청북부지회",
    "부산울산동부지회",
    "부산울산서부지회",
    "서울제주동부지회",
    "서울제주북부지회",
    "서울제주남부지회",
    "인천경기남부지회",
    "인천경기서부지회",
    "해외지회",
  ],
};

export const ALL = Object.values(LOCAL_NAMES).flatMap((names) => names);
LOCAL_NAMES["전체"] = ALL;

export function getLocalNames(local) {
  return ["전체"].concat(LOCAL_NAMES[local]);
}
