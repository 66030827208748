<template>
  <el-radio-group :modelValue="modelValue" @update:modelValue="update">
    <el-radio-button v-for="[val, label] in choices" :key="val" :label="val">{{
      label
    }}</el-radio-button>
  </el-radio-group>
</template>
<script>
import { ElRadioButton, ElRadioGroup } from "element-plus";

export default {
  name: "PresetRadio",
  componentName: "PresetRadio",
  components: {
    [ElRadioButton.name]: ElRadioButton,
    [ElRadioGroup.name]: ElRadioGroup,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    choices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    update(newValue) {
      this.$emit("update:modelValue", newValue);
      this.$emit("change", newValue);
    },
  },
};
</script>
