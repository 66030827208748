<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">내문의사항 {{ modalModeText }}</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <label class="form-control-label ml-3">요청업무선택</label>
        <div class="row ml-0">
          <preset-select
            placeholder="선택필수"
            v-model="newData.questPart"
            @change="questionPart2(newData)"
            :choices="[
              ['법회'],
              ['천결'],
              ['수련'],
              ['삼보수호비'],
              ['자격'],
              ['정기활동'],
              ['교육수련'],
              ['기타'],
            ]"
          />
        </div>
        <label class="form-control-label ml-3">소분류선택</label>
        <div class="row ml-0">
          <preset-select
            placeholder="선택필수"
            v-model="newData.questPart2"
            :choices="questPart2"
          />
        </div>
        <div class="row">&nbsp;</div>
        <div class="form-group mt-2" v-if="newData.question">
          <label class="form-control-label">문의내용</label>
          <textarea
            disabled
            class="form-control"
            v-model="newData.question"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group mt-2" v-if="newData.resultContent">
          <label class="form-control-label">답변내용</label>
          <textarea
            disabled
            class="form-control"
            v-model="newData.resultContent"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group mt-2">
          <label class="form-control-label" v-if="newData.question"
            >문의내용추가</label
          >
          <label class="form-control-label" v-else>문의</label>

          <textarea
            class="form-control"
            v-model="newQuestion"
            rows="5"
          ></textarea>
        </div>
        <div class="row">&nbsp;</div>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";

export default {
  components: {
    Modal,
    PresetSelect,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        questPart: "",
        questPart2: "",
        question: "",
      },
      newQuestion: "",
      questPart2: [["", "업무부터 선택"]],
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    questionPart2(newData) {
      if (newData.questPart == "법회") {
        this.questPart2 = [["법회출석", "법회출석"]];
      } else if (newData.questPart == "천결") {
        this.questPart2 = [
          ["입재식", "입재식"],
          ["결사금", "결사금"],
        ];
      } else if (newData.questPart == "삼보수호비") {
        this.questPart2 = [
          ["", "문의에 해당하는 년도와 달을 정확히 입력해주세요"],
        ];
      } else if (newData.questPart == "수련") {
        this.questPart2 = [
          ["깨달음의장", "깨달음의장"],
          ["나눔의장", "나눔의장"],
          ["명상", "명상"],
        ];
      } else if (newData.questPart == "정기활동") {
        this.questPart2 = [["정기활동이력", "정기활동이력"]];
      } else if (newData.questPart == "기타") {
        this.questPart2 = [["기타", "기타"]];
      }
    },
    // 등록하기
    onAdd() {
      const url = `service/myinformation/manager?local=aa`;
      api.get(url).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;
        let teamChief = "";
        let memberChief = "";
        for (const row of d2) {
          if (row.role === "지부팀장") {
            teamChief = row.email;
          } else if (row.role === "지부회원담당") {
            memberChief = row.email;
          }
        }
        this.newQuestion = this.newQuestion.trim();
        if (this.newQuestion.length < 10) {
          alert("문의 내용은 10글자 이상 작성하셔야 등록됩니다.");
          return;
        }
        if (!this.newData.questPart) {
          alert("요청업무을 선택해주세요!!");
          return;
        }

        const data = JSON.parse(JSON.stringify(this.newData));
        if (
          this.newData.questPart === "법회" ||
          this.newData.questPart === "수련" ||
          this.newData.questPart === "교육수련" ||
          this.newData.questPart === "기타"
        ) {
          data.resulter = teamChief;
        } else {
          data.resulter = memberChief;
        }

        data.question = this.newQuestion;

        api.post("service/myinformation/question", { data }).then(() => {
          this.$emit("addDone", data);
          this.hideModal();
        });
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      if (data.question) {
        data.question = this.newQuestion + "\n\n" + data.question;
      }
      api.put(`service/myinformation/question/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.question = "";
      this.newData.questPart = "";
      this.newData.questPart2 = "";
      this.newQuestion = "";
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.newQuestion = "";
      this.loadModal(
        `service/myinformation/question/${row.id}`,
        row,
        (data) => {
          return {
            question: data.question,
            questPart: data.questPart,
            questPart2: data.questPart2,
            resultContent: data.resultContent,
          };
        }
      );
    },
  },
};
</script>
